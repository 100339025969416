.chart-box {
  .MuiTypography-h4 {
    font-size: 20px !important;
  }
}

/**
BASIC KPI
 */
.basic-kpi {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0;
  margin-bottom: 1.5rem !important;

  .inner {
    margin-top: 4rem;
  }
/*
  .layout-row {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
    align-items: flex-start !important;
    margin-top: 1.5rem;

    .layout-col-2 {
      grid-column: span 2 / span 2 !important;

      @media (min-width: 1024px) {
        grid-column: span 1 / span 1 !important;
      }
    }

    .layout-col-5 {
      grid-column: span 5 / span 5 !important;

      @media (min-width: 1024px) {
        grid-column: span 6 / span 6 !important;
      }
    }
  }*/

  .gauge {
    width: 100%;
    margin-bottom: 3rem;
    align-self: flex-start;

    @media (min-width: 1024px) {
      width: 25% !important;
      margin-bottom: 0;
    }
  }
/*
  .layout-row__1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;



    .measures-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 0.75rem !important;
      row-gap: 3.5rem !important;
      margin-bottom: 3rem;
      padding-top: 5rem;

      @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
      }

      @media (min-width: 1024px) {
        width: 75% !important;
        margin-bottom: 0;
      }
    }

    @media (min-width: 1024px) {
      flex-direction: row !important;
    }
  }

  .layout-row__2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 6rem;

    .chart-box {
      grid-column: span 2 / span 2;
      padding: 1.25rem;

      @media (min-width: 1280px) {
        grid-column: span 2 / span 2 !important;
      }
    }

    .generic-chart {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }*/
}

/**
 MET KPI
 */

.met-kpi {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  margin-top: 20px !important;


  .spacer {
    margin-top: 4rem;
  }

/*
  .layout-row__1 {
    grid-column: span 8 / span 8;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 0.75rem;
    align-items: flex-start;

    .simple-chart {
      grid-column: span 6 / span 6;
    }
  }

  .layout-row__2 {
    grid-column: span 8 / span 8;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
    align-items: center;

    .item {
      grid-column: span 2 / span 2
    }
  }

  .layout-row__3 {
    grid-column: span 8 / span 8;
    width: 100%;
    padding-top: 6rem;
  }

 */

  .distance-chart {
    width: 100%;
    grid-column: span 8 / span 8;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    margin-top: -42px;
  }

  .power_zones-chart {
    grid-column: span 8 / span 8;
    padding: 1.25rem;

    .wrapper {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media (min-width: 1280px) {
      grid-column: span 4 / span 4 !important;
    }
  }
}

div[id^="panel"]{
  p{
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
  }
}

