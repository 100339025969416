.general-bold-text() {
  font-size: var(--font-size-main) !important;
  font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
}

.general-data-text() {
  font-size: var(--font-size-main) !important;
  font-family: var(--font-family-main) !important;
  font-weight: 400 !important;
}

