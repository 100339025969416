.time-chart-container,
.track-tab-container {
  margin-top: 1.5rem;
}

.track-title-view {
  margin-bottom: 3.5rem;
}

.track-series__multiline {
  .highcharts-container, .highcharts-container svg {
    width: 100% !important;
  }
}

.track-series__selects {
  margin-bottom: 1rem;
  display: flex !important;
  justify-content: end !important;
  gap: 10px !important;
  width: 100%;
}

.track-bottom-spacer {
  margin-top: 1rem;
}

.box-tracks-buttons {
  display: flex !important;
  justify-content: end !important;
}

.hide-column {
  display: none !important;
}

.icon-label-track {
  margin-top: 68px !important;
}

.horizontal-perc-bar-box {
  position:relative !important;
}

.zone-value-uom {
  position: absolute !important;
  top: -23.5px !important;
  right: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 0.2rem !important;
  span{
    width: fit-content !important;
  }
}

.new-session {
  display: flex;
  &-calendar {
    flex: 1;
    width: 380px;
    max-width: 380px !important;
  }
  &-track-selection {
    flex: 1;
  }
  @media (max-width: 1150px), (max-width: 1055px), (max-width: 1000px), (max-width: 905px) {
    flex-direction: column !important;
  }
  @media (max-width: 1055px) {
    padding-top: 50px !important;
  }
  @media (max-width: 1000px) {
    padding-top: 100px !important;
  }
  @media (max-width: 905px) {
    padding-top: 125px !important;
  }
}

.mpe-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  font-family: var(--font-family-alt);
  font-size: 24px;
  font-weight: 400;
  color: var(--secondary-color)
}


