.drill-navigator {
  ul {
    font-family: var(--font-family-main);
  }
}

/* SESSION DRILLS */
.session-drills {
  .session-drills__name {
    font-family: var(--font-family-main);
    font-size: var(--font-size-main);
    line-height: var(--line-height-main);
    height: 42px;
    margin-top: 12px;
  }
}

/** DRILLS LIST */
.drills-list {
  li,
  li a {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .single-drill {
    padding-left: 1rem;
  }

  .single-drill,
  .whole-drill {
    &.has-links:hover {
      color: var(--primary-color);
    }
  }

  .current {
    font-weight: 700;
    font-family: var(--font-family-main);
  }

  .current:hover {
    color: var(--secondary-color);
  }
}

.bold-label {
  font-weight: 700;
  font-family: var(--font-family-main);
  color: var(--secondary-color)
}

.session-selection-chart .highcharts-plot-band-label,
.drills-selection__athlete {
  .general-bold-text();

  color: var(--text-secondary);
  fill: var(--text-secondary);
}

.drill__total-time,
.drill__single-band {
  .general-data-text();
}

span.arrows {
  width: 100%;
  display: flex;
  justify-content: end;
  position: relative;
  top:-0.5px;

  svg+svg {
    margin-left: 0.25rem;
  }

  .arrow-up_svg__arrow-up,
  .arrow-outline-up_svg__arrow-outline-up {
    color: var(--primary-gradient-4) !important;;
  }

  .arrow-down_svg__arrow-down,
  .arrow-outline-down_svg__arrow-outline-down {
    color: var(--primary-color) !important;
  }
}

.row-active input {
  color: green;
}

.session-charts-header{
  display: flex !important;
  justify-content: space-between !important;
  height: fit-content !important;
  div{
    margin: 0 !important;
  }
}

.session-row-state--lo {
  pointer-events: none;
  opacity: 0.5;
}

.session-row-state--lo {
  background-color: #eee;
}

.session-row-state--lo,
.session-row-state--p,
.session-row-state--l {
  .MuiDataGrid-cell--textCenter .loader-icon {
    display: inline-flex !important;
    margin-left: -0.5rem !important;
  }
}

.session-is-loading {
  pointer-events: none;
  position: relative;
  opacity: 0.4;
}

.table-status__prefix {
  width: 28px;
  height: 28px;
  display: inline-block;
  position: relative;
  top:5px;
  left:-5px;
}

.drills-selection-tooltip {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0.5rem;
}

.selector-rpe-players > button > span > div {
  margin-left: 0 !important;
}
