
@media (min-width: 640px) {
  .team-list {
    width: 640px !important;
  }
}

.labelAcronym {
  .MuiFormControl-root .MuiFormLabel-root {
    text-transform: none !important;
  }

  &>label {
    text-transform: none !important;
  }
}

.hidden-arrow {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.style_editable_chip {
  margin-left: 2px !important;
}

.style_editable_chip:hover {
  background-color: #e0e0e0 !important;
}

.title_table_tabs {
  font-family: var(--font-family-main);
  font-weight: 500;
  font-size: 20px !important;
  height: 24px;
  line-height: 24px !important;
  border-radius: 12px;
  margin-left: 10px !important;
  span{
    margin-left: -10px !important;
  }
}

.title_table_tabs::after {
  border: none !important;
}

.team-selector-list {
  padding: 0 !important;
}

.kpi_thresholds_icon {
  margin-left: -16.5px !important;
}

span.MuiSlider-valueLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  span:first-child {
    width: 38px;
    height: 38px;
    position: relative;
    top: -7px;
    left: -3px;
    & > span:first-child {
      width: max-content;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.logo-type-row {
  margin-top: -16px !important;
  margin-bottom: 8px !important;
}

.remove-player-icon {
  path {
    color: var(--stroke-gray) !important;
  }
}