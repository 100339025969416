.accordion-component {
  position: relative;

  .accordion-component__title {
    position: absolute;
    right: 1rem;
    left: 1rem;
    top: 0.75rem;
  }
}

/* Accordion */
.accordion {
  border-radius: 12px;
  border: 1.5px solid;
  border-color: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12) !important;
  margin-bottom: 10px;
}

.accordion:hover:not(.Mui-disabled) {
  border-color: var(--primary-color);
}

.accordion:hover:not(.Mui-disabled) .MuiAccordionSummary-root {
  color: var(--primary-color) !important;
}

.MuiAccordionSummary-expandIcon {
  position: absolute !important;
  right: 16px !important;
}

.MuiAccordionDetails-root {
  &.accordion-with-mount {
    display: block;
  }
}
