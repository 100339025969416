/* BaseChip active */
/** @todo nomi troppo generici, verificare */
.base-chip {
  margin-bottom: 0.5rem;
}

.selected, 
.selected-no-hover,
.unselected {
  div{
  margin: 0 !important;
  }
}

.selected,
.selected-no-hover {
  background-color: var(--primary-color) !important;
}

.selected>svg,
.selcted-no-hover>svg {
  color: var(--secondary-color);
}

.selected:hover {
  background-color: var(--secondary-color) !important;
  color: #ffffff !important;
}

.unselected>svg,
.unselected-no-hover>svg {
  color: var(--secondary-color) !important;
}

.unselected:hover {
  background-color: var(--primary-color) !important;
}

/* BaseChip hover */
.chipClassHover:hover {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.chipClassStatic {
  box-shadow: none !important;
}


.MuiInputBase-multiline{
  height: 100% !important;
}

textarea{
  align-self: start;
}

