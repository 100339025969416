
.MuiGridFilterForm-linkOperatorSelect {
  visibility: hidden !important;
  display: none !important;
}

.MuiDataGrid-cell {
  svg {
    color: var(--secondary-color) !important;
  }
}

.MuiDataGrid-cell--textCenter {
  div{
    margin: 0 !important;
  }
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: rgba(0, 221, 181, 0.1) !important;
}

.MuiGrid-spacing-xs-2 {
  margin-top: 0;
}

.layout-row + .layout-row {
  margin-top: 8px;
}

.rpe-notes {
  padding: 8px;
}

.MuiDataGrid-rowCount {
  text-transform: lowercase !important;
}