/** LIST FIELD **/
.list-field {
  .start-component {
    position: absolute;
    left: -4px;

    svg {
      color: var(--secondary-color);
    }
  }

  .end-component {
    position: absolute;
    right: -4px;

    svg {
      color: var(--secondary-color);
    }
  }

  .MuiListItem-root {
    flex-direction: column;
  }
}

.list-field-popover {
  button:hover {
    color: var(--primary-color);
  }
}
