/**
 LAYOUT
 */

.body-wrapper {
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: white;
}

#content_gpexe {
  background: url(../img/IconSidebar/X-gray.svg) rgb(243, 244, 246) right bottom no-repeat !important;
  background-position: right -50px bottom 100px !important;
  background-size: 250px 250px !important;
  background-attachment: fixed !important;
  flex: 1 1 0 !important;
  position: relative;
  overflow-y: auto;
  padding-top: 60px;

  &:focus {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  &.content_gpexe--open {
    margin-left: 235px;
  }

  &.content_gpexe--closed {
    margin-left: 77px;
  }
}

.fullscreen-component {
  #content_gpexe {
    &.content_gpexe--open,
    &.content_gpexe--closed {
      margin-left: 0 !important;
      padding: 0 !important;
    }
  }

  .tracks-player > .layout-row,
  .tracks-player > .layout-row .tracks-player-container,
  .tracks-player > .layout-row .tracks-player-column,
  #main-content-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiGrid-spacing-xs-2,
  .tracks-player-column {
    width: 100% !important;
  }

  // .webplayer-controls,
  .player__zoom {
    display: none !important;
  }

  .ground-component {
    border: none !important;
  }
}

.sidebar-trigger {
  position: fixed !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  background-color: rgb(209, 213, 219) !important;
  margin-left: -0.75rem !important;
  top: 40px;
}

.no-border {
  &::after {
    display: none !important;
  }
}

.row-invalid-profile {
  opacity: 0.5;
}

.row-loading {
  pointer-events: none;
  opacity: 0.5;

  /*
  .MuiCheckbox-root {
    opacity: 0;
  } */
}

.z-modal {
  z-index: 99999 !important;
}

.z-0 {
  z-index: 0;
}

.bottom-spacer {
  margin-bottom: 2.5rem;
}

.poppoverMaxHeigh {
  max-height: calc(100vh - 150px);
}

.popover-col__l {
  display: inline-block;
  flex-grow: 1;
}

.popover-col__r {
  display: inline-block;
  width: 50px;
  text-align: right;
  font-weight: 700;
}

.outlined-wrapper {
  .MuiButtonBase-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiBox-root .MuiChip-root:first-child {
    margin-left: 0;
  }
}

.box-flex {
  display: flex;
  .MuiBox-root {
    width: 50%;
  }
  .MuiBox-root:first-child {
    padding-right: 16px;
  }
}

.state-waiting,
.state-processing {
  position: relative;

  .MuiInputBase-root {
    opacity: 0.5;
    pointer-events: none;
  }

  &::after {
    content: 'Please wait, data is being processed';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: rgb(66, 202, 184);
    text-shadow: 0 1px 2px white, 0 2px 4px rgba(66, 202, 184, 128);
  }
}

.no-label {
  margin-top: 5px;

  .MuiFormControl-root {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.clickable {
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

.not-clickable {
  cursor: default;

  input {
    cursor: default;
  }
}

.h-screen {
  height: 100vh;
}

.fixed {
  position: fixed;
}

.block {
  display: block;
}

.items-center {
  align-items: center;
}

/* @TODO fix */
.cursor-pointer,
.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default;
}

/* @TODO vedere di unificare con quello sopra o estendere quello sopra */
.cursor-default-textinput {
  .MuiInputBase-input {
    cursor: default;
  }
}

.none {
  display: none !important;
}

.divider-with-text {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

/** @todo verificare se metter in App */
.MuiDataGrid-renderingZone .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: none !important;
}

.MuiDialogContent-root {
  padding: 8px 15px !important;
}

.MuiDialogActions-spacing {
  padding: 12px 0 !important;
}

.MuiPaper-elevation1, .MuiPaper-elevation3, .MuiPaper-root.MuiAccordion-root {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);
}

.paper-margin-bottom {
  margin-bottom: 12px !important;
}

.paper-default-border {
  border: 1px solid rgba(224, 224, 224, 1)
}

.backdrop-style {
  z-index: 99999;
  color: white;
}


.MuiSelect-root,
.MuiInputBase-root:not(.Mui-disabled),
.MuiInputLabel-root:not(.Mui-disabled):not(.Mui-focused),
.MuiDataGrid-cell {
  color: var(--text-secondary) !important;
}

.MuiSelect-select {
  & ~ .MuiInputAdornment-root {
    .MuiLink-root {
      background-color: #fff;
      z-index: 10;
      padding: 3px;
    }
  }
}

.MuiInputAdornment-root {
  a,
  button {
    &:hover {
      svg {
        fill: rgb(0, 221, 181);
      }
    }
  }
}

.select-field:not(.readonly) .MuiFormControl-root:not(.disabled):not(.readonly) {
  .MuiInputBase-root {
    &.Mui-focused {
      fieldset {
        border-color: var(--primary-color) !important;
      }
    }
  }
}

.editable-select-field {
  :hover {
    border-color: var(--primary-color) !important;
  }

}


.name-field {
  input {
    text-transform: capitalize !important;
  }
}

.MuiOutlinedInput-multiline {
  &.MuiOutlinedInput-marginDense {
    padding-top: 12px !important;
  }
}

.MuiTextField-root {
  label.Mui-focused {
    color: var(--secondary-color);
  }
}

.MuiTextField-root {
  width: 100%;
}

.MuiFormControlLabel-root {
  &[aria-readonly='true'] {
    cursor: default !important;

    input,
    select,
    button {
      cursor: default !important;
    }
  }
}

/* @todo spostare su app */

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

@media (max-width: 1200px) {
  .MuiDrawer-paper {
    width: 100% !important;
    height: 100% !important;
    overflow-y: visible !important;
  }
}

.box-text-disabled {
  color: var(--border-disabled)
}

.leaflet-container {
  z-index: 10;
}

.badge-expired-teams {
  position: relative !important;
}

.badge-expired-teams::after {
  content: "!";
  position: absolute !important;
  width: 21px;
  height: 21px;
  top: -3px;
  right: -6px;
  border-radius: 50%;
  color: white;
  background-color: var(--secondary-color);
  font-family: var(--font-family-alt);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiFormControlLabel-root[aria-readonly="true"] {
  svg {
    fill: var(--stroke-gray) !important;
  }
}

.select-type label + label {
  margin-left: 2rem !important;
}

.get-gradient-box {
  display: flex;
  padding: 0.25rem 0rem;
}

.anchor-link {
  display: flex;
  flex-direction: row;
  align-content: center;

  &:hover {
    svg {
      fill: var(--primary-color) !important;
    }

    p {
      color: var(--primary-color) !important;
    }
  }
}

.select-field-loading {
  width: fit-content !important;
  padding: 0 !important;

  div:first-child {
    width: 25px !important;
    height: 25px !important;
    position: relative !important;
    top: 7px !important;
  }
}

.screenshot-running {
  .hide-on-screenshot {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}

.disabled-link {
  pointer-events: none;
}

body.show-ui-message {
  #main-content-wrapper {
    pointer-events: none;
    position: relative;
    min-height: calc(100vh - 110px);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: repeating-linear-gradient(45deg,
      #606dbc,
      #606dbc 10px,
      #465298 10px,
      #465298 20px);
      z-index: 50;
      opacity: 0.1;
    }

    &::after {
      content: attr(data-message);
      display: block;
      position: fixed;
      top: initial;
      bottom: 80px;
      left: initial;
      right: 20px;
      z-index: 500;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #fea;
      font-size: 1.5rem;
      box-shadow: 0 3px 12px 3px rgba(0, 0, 0, 0.2);
    }
  }
}
