/**
 DEVELOPMENT MODE
 */

.dev-bar {
  opacity: 0.2;
  transition: opacity 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }
}

.sidebar-closed {
  .dev-bar {
    display: none;
  }
}

.sidebar-open:hover {
  .dev-bar {
    opacity: 0.9;
  }
}

.testcomponents__wrapper {
  .MuiCollapse-root {
    border: 1px solid lightgray;
  }
}

/** DEBUG  */

.debug-acl {
  position: relative;

  &.debug-acl-allowed {
    box-shadow: 0 0 3px 1px rgba(40, 160, 40, 0.8);

    &:before {
      background-color:rgba(40, 160, 40, 1);
    }
  }

  &.debug-acl-not-allowed {
    box-shadow: 0 0 3px 1px rgba(200, 60, 60, 0.8);

    &:before {
      background-color: rgba(200, 60, 60, 0.8);
    }
  }

  &::before {
    content: attr(data-title);
    position: absolute;
    bottom: -18px;
    right: 0;
    text-align: right;
    padding: 2px 4px;
    color: white;
    line-height: 1;
    opacity: 0.5;
  }

  &:hover::before {
    opacity: 1;
  }
}

#dev-status {
  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 600px;
  background-color:#fff;
  padding: 5px;
  z-index:100;
  border-radius: 4px;
  box-shadow: 0 4px 8px 4px rgba(0,0,0,0.3);
  transition: transform 0.3s;
  transform: translatey(98%);

  &:hover {
    transform: translatey(0);
  }

  &::before {
    content: 'debug';
    color: darkgreen;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
    position:absolute;
    background-color: white;
    top: -25px;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.3);
  }

  table,
  td {
    border: 1px solid #aaa;
  }

  .cols,
  .cols > div {
    border: 1px solid #aaa;
  }

  .cols > div {
    padding: 5px;
  }

  .cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5px;
  }
}

#debug-window {
  box-shadow: 0 0 12px 12px rgba(0,0,0,0.2);
  bottom: 50px;
  overflow: scroll;
  height: 500px;
  max-height: 40vh;
  position: fixed;
  right: 0;
  width: 800px;
  background-color: #fff;
  padding: 10px;
  opacity: 0.8;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  table {
    border: 1px solid;
  }

  tr {
    border: 1px solid;
  }

  hr {
    border: 1px solid green;
    margin-bottom: 0.5rem;
  }
}

