#customized-dialog-title{
    padding: 14px 0px 14px 15px !important;
    margin: -2px 0 -2px 0 !important;

    & > .MuiTypography-h6 {
        font-family: var(--font-family-alt) !important;
        font-weight: 600 !important;
    }
}

#modal-close-icon {
    position: absolute;
    top: 4px;
    right: 4px;
}

.other-modals {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.session-exports-pdf-wrapper {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 12px 12px 12px 8px !important;
}

.session-exports-pdf-inner {
    display: flex !important;
    width: fit-content !important;
    height: fit-content !important;
    span{
        margin: 0 !important;
    }
}

.pagemessage-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    div {
        margin-left: 3px !important;
        svg {
            cursor: default !important;
        }
    }
}

.modal-buttons-box {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 8px !important;
}

.button-action-svg-fill-white {
    height: 30px !important;
    width: fit-content !important;
    .MuiButton-startIcon {
        width: 20px;
        height: 20px;
    }
    &:hover {
        svg {
            path{
                fill: var(--secondary-color) !important;
            }
        }
    }
    svg {
        height: fit-content;
        width: fit-content;
        path{
            fill: white !important;
            width: 10px;
            height:10px;
        }
    }
}
