/* src
.MuiAppBar-root {
    .MuiButton-root:not(.force-large) {
        overflow:hidden;
        width: 36px;
        height: 36px;
        padding: 12px;
        min-width: 36px;

        .MuiButton-label {
            color: transparent;

            .MuiButton-startIcon {
                position: absolute;
                margin: 0;
                color: var(--secondary-color);
                z-index: 10;
            }
        }
    }
}
 */
@media screen and (max-width: 1650px) {
  @supports (-webkit-hyphens:none) {
    .MuiAppBar-root .MuiButton-root:not(.force-large) {
      overflow: hidden;
      width: 36px;
      height: 36px;
      padding: 12px;
      min-width: 36px;
    }

    .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label {
      color: transparent;
    }

    .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label .MuiButton-startIcon {
      position: absolute;
      margin: 0;
      color: var(--secondary-color);
      z-index: 10;
    }
  }
}


/* Safari 10.1+ */
@media (max-width: 1650px) {
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
      .MuiAppBar-root .MuiButton-root:not(.force-large) {
        overflow: hidden;
        width: 36px;
        height: 36px;
        padding: 12px;
        min-width: 36px;
      }

      .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label {
        color: transparent;
      }

      .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label .MuiButton-startIcon {
        position: absolute;
        margin: 0;
        color: var(--secondary-color);
        z-index: 10;
      }
    }
  }
}

/* Safari 7.1+ */
@media screen and (max-width: 1650px) {
  _::-webkit-full-page-media, _:future, :root .safari_only {
    .MuiAppBar-root .MuiButton-root:not(.force-large) {
      overflow: hidden;
      width: 36px;
      height: 36px;
      padding: 12px;
      min-width: 36px;
    }

    .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label {
      color: transparent;
    }

    .MuiAppBar-root .MuiButton-root:not(.force-large) .MuiButton-label .MuiButton-startIcon {
      position: absolute;
      margin: 0;
      color: var(--secondary-color);
      z-index: 10;
    }
  }
}
