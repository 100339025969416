.bridge-widget-container {
  display: flex !important;
  justify-content: start !important;
  align-items: start !important;
  width: max-content !important;
}

.MuiDialogContent-root{
  overflow-y: hidden !important;
}

.bridge-widget-download{
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: start !important;
  gap: 0.1rem !important;
  width: fit-content !important;
}

.bridge-widget-version {
  display: inline !important;
  width: fit-content !important;
  font-family: var(--font-family-alt) !important;
  font-weight: 600 !important;
}

.bridge-widget-label {
  display: inline-block !important;
  margin-right: 0.3rem !important;
  margin-left: 0.3rem !important;
}

.bridge-widget-requirements{
  margin-top: 0.5rem !important;
  margin-left: 0.3rem !important;
}

