.MuiCardHeader-root {
  .MuiTypography-root {
    color: var(--text-secondary) !important;
  }
}

.BoxCard {
  border: 1px solid var(--border-card-color) !important;
  border-radius: 12px;
  width: 200px;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0 2px 8px 0;
    border-color: var(--primary-color) !important;
  }
}

.BoxCardFlicker {
  border: 2px solid var(--primary-color) !important;
  border-radius: 12px;
  width: 200px;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);

  animation: flickerAnimation 1.5s infinite;

  scroll-margin-top: 70px;
  
  
  &:hover {
    animation: none;
    box-shadow: 0 2px 8px 0;
    border-color: var(--primary-color) !important;
  }
}

@keyframes flickerAnimation {
  0% {
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);
  }
  25% {
    box-shadow: 0 2px 8px 0;
  }
  50% {
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow: 0 2px 8px 0;
  }
  100% {
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2);
  }
}

.BoxCardBasic {
  background-color: #ffffff !important;
  border-radius: 12px;
  max-width: 200px;
  min-width: 180px;
  div.MuiCardMedia-root {
    margin-top: -12px !important;
  }
}

.BoxCardBasic-shortnameBox {
  margin: 0px 20px 8px 20px !important;
  padding: 0 !important;
  cursor: pointer;
  &:hover{
    color: var(--primary-color);
  }
}

.BoxCardBasic-shortnameBox-noLink {
  width: 200px !important;
  margin: 0px 20px 8px 20px !important;
  padding: 0 !important;
  cursor:default;
}
