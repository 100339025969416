
.table-header__subtitle {
  font-family: var(--font-family-alt) !important;
  font-weight: 400 !important;
}

.table-header__subtitle-empty {
  font-family: var(--font-family-alt) !important;
  font-weight: 400 !important;
  background-color: white !important;
}

.table-header-unit {
  min-height: 12px;
}

.kpi-table-header,
.generic-table-header {
  display: flex;
  flex-direction: column;
  line-height: 12px;
  font-size: 18px;
  align-items: flex-end;
  gap: 0.5rem;

  .table-header-unit {
    font-family: var(--font-family-alt);
    font-weight: 400;
  }
}


.table-title {
  font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  color: #828282 !important;
  margin-left: 20px !important;
}

.table_button:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
  .circular-button:hover {
    cursor: pointer !important;
  }
}

.table__default {
  background-color: white;
  border-radius: 0.75rem !important;
}

.track-zone-table {
  border-radius: 0.75rem !important;
}

.table-bold-row {
  font-weight: bold !important;
}

.table-custom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    padding: 0px 16px;
  }
}

.MuiDataGrid-main .loader-icon {
  display: inline-block;
  width: auto;
  margin-top: 8px;
  margin-right: 8px;
}

.row-loading {
  .MuiCheckbox-root {
    svg {
      display: none;
    }
  }

  .MuiCheckbox-root::after {
    content: '';
    width: 18px;
    height: 18px;
    border: 2px solid var(--secondary-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: loadingRotation 1s linear infinite;
  }
}
/* vertical spacing and align for single row header */
.vertical-top {
  display: flex;
  flex-direction: column;

  &::after {
    content:'';
    display: block;
    width: 10px;
    height: 20px;
  }
}

.table-cell-placeholder.loading-gradient {
  width: 100%;
  height: 25px;
  border-radius: 3px;
}

@keyframes loadingRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
}

/* stili per celle con tag che vanno a capo */
.MuiDataGrid-root .MuiDataGrid-cell--withRenderer {
  align-items: center !important;
}

.MuiDataGrid-cell[data-field="categories"],
.MuiDataGrid-cell[data-field="tags"] {
  position: relative;
  align-items: flex-start !important;
  & > div {
    flex-wrap: wrap  !important;
  }

  & div[data-long="true"] {
    &::after {
      content: '( ... )';
      position: absolute;
      right: 5px;
      top:0;
      bottom: 0;
      color: var(--secondary-color);
    }
  }
}

.MuiFormLabel-root {
  font-family: var(--font-family-main) !important;
}

.tags-extended-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 5px;
}

.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell {
  padding:0 !important;
}

.nolink-col-left {
  padding-left:16px !important;
}

.nolink-col-right {
  padding-right:16px !important;
}

.MuiDataGrid-footerContainer {
  margin-top:7px !important;
  margin-bottom: 3px !important
}

[aria-label="Previous page"], [aria-label="Next page"] {
  padding:5px!important;
}

.MuiTablePagination-actions {
  margin-left: 70px !important;
}

.pagination-arrows {
  padding:5px !important;
}

.pagination-arrows[aria-label='enabled'] {
  &:hover {
    background-color: #f5f5f5;
    border-radius: 50%;
  }
}

.first-page-button {
  position: absolute;
  right: 125px;
  cursor: pointer; 
  z-index: 1000;
}

.last-page-button {
  margin-right: 15px; 
  margin-left: 3px; 
  cursor: pointer;
}

.hide-table-header {
  visibility: hidden;
}

.MuiTablePagination-actions .MuiIconButton-root:hover,
.last-page-button[aria-label="enabled"]:hover,
.first-page-button[aria-label="enabled"]:hover {
  background-color: var(--green-row-hover) !important;
}

.last-page-button[aria-label="disabled"]:hover,
.first-page-button[aria-label="disabled"]:hover {
  cursor: default;
}
