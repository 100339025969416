.counter-accordion {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  min-width: 20px !important;
  max-width: 26px !important;
  max-height: 20px !important;
  background-color: #828282 !important;
  color: #ffffff !important;
  font-family: var(--font-family-main) !important;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  margin-right: 32px;
  .MuiChip-label {
    overflow: visible !important;
  }
}

.templates-kpi-paper {
  display: flex !important;
  justify-content: space-between !important;
  padding: 0px 22.5px 0px 8px !important;

  div:first-child{
    width:fit-content !important;
  }
  .templates-counter {
      font-family: var(--font-family-alt) !important;
      font-size: 24px !important;
      font-weight: 500 !important; 
      width: fit-content !important;
      color:var(--text-secondary) !important;
  } 
}

.sortable-kpis-box {
  div:first-child {
    justify-content: flex-start !important;
  }
}
