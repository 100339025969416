.account-menu {
  font-family: var(--font-family-alt);
  font-size: var(--font-size-main);
}

.account-email {
  font-family: var(--font-family-alt);
  font-weight: bold;
  text-align: center;
}

.account-menu--item {
  cursor: pointer;

  span {
    font-family: var(--font-family-alt);
    font-size: var(--font-size-main);
  }

  .MuiListItemIcon-root {
    min-width: 40px;
  }

  &:hover {
    background-color: rgb(243, 244, 246);
  }
}
