.editable-chip {
  max-width: 250px;

  .MuiOutlinedInput-root {
    border-radius: 21px;
    background-color: #fff;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--primary-color);
  }
}

.input-naked {
  fieldset {
    border: none;
  }
  .MuiInputLabel-formControl {
    display: none;
  }
}

.MuiCheckbox-root + .MuiFormControlLabel-label {
  color: var(--text-secondary);
}
