
.MuiTooltip-tooltip {
  color: var(--secondary-color) !important;
  background-color: rgba(255, 255, 255, 0.90) !important;
  border: 1px solid var(--secondary-color) !important;
  font-family: var(--font-family-alt) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0.1px !important;
  border-radius: 9px !important;
}

.MuiTooltip-arrow {
  &::before {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    background-color: rgba(255, 255, 255, 0.90) !important;
  }
}

.MuiTooltip-popper {
  text-align: center !important;
}
