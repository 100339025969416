/* TABS */
.tab-container {
  position: relative;
  z-index: 10;

  h4.MuiTypography-root {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      width: 48px;
      height: 48px;
    }

    &::after {
      content: '';
      display: block;
      border-bottom: 1px solid;
      position: absolute;
      bottom: 0;
      left: 66px;
      right: 0;
    }
  }

  &.tab-container__generic {
    h4.MuiTypography-root {
      font-size: 18px;

      &::after {
        display: none;
      }
    }

    .MuiTabs-scroller {
      svg path {
        stroke: none !important;
      }

      button:not(.Mui-selected) {
        .MuiTypography-button {
          color: var(--secondary-color);
        }

        path {
          fill: var(--secondary-color);
        }
      }
    }
  }

  .MuiTabs-root {
    .Mui-selected {

      path,
      line {
        stroke: var(--primary-color);
      }

      color: var(--primary-color);

      .MuiTypography-colorPrimary {
        color: var(--primary-color);
      }
    }
  }
}

.tab-container__no-bg {
  .MuiTabs-flexContainer {
    background-color: transparent;

    button:first-child {
      border-top-left-radius: 12px !important;
    }

    button:last-child {
      border-top-right-radius: 12px !important;
    }
  }
}

.MuiTabs-flexContainer {
  .Mui-selected {
    box-shadow: rgb(0 0 0 / 20%) 0 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
  }
}

.MuiTabs-flexContainer {
  button {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);

    &.Mui-selected {
      z-index: 10;
    }
  }

}

.MuiTabs-indicator {
  z-index: 20;
}

.tab-container__no-bg {
  background-color: transparent !important;

  header {
    z-index: 10;
  }

  &>.MuiPaper-root {
    z-index: 20;
    position: relative;
  }
}

.MuiTabs-root:not(.Mui-selected) {
  background-color: transparent !important;
}

.tab-container > .MuiPaper-root {
  background-color: transparent;
  box-shadow: none !important;

  & > .MuiBox-root {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12) !important;
  }
}

.MuiPaper-elevation1, .MuiPaper-root.MuiAccordion-root {

  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12) !important;

}
