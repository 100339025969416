.link-style {
  &>svg:hover {
    color: var(--primary-color) !important;
  }
}

.link-style:hover {
  color: var(--primary-color) !important;

  &>svg {
    color: var(--primary-color) !important;
  }
}
