.editable-text-field {
  textarea {
    padding: 0 0 1px 0 !important;
  }

  .MuiInputAdornment-positionEnd {
    margin-right: -6px;
  }

  .MuiIconButton-root {
    padding: 6px;
  }
/*
  label.MuiInputLabel-root:not(.Mui-focused) {
    color: var(--label-disabled-color) !important;
  }*/
}
