.slider-kpi {

  .MuiSlider-root {
    color: var(--secondary-color);
  }

  .MuiSlider-root,
  .MuiSlider-thumb:hover,
  .MuiSlider-thumb:focus {
    box-shadow: none !important;
    cursor: default !important;
  }

  .MuiSlider-mark {
    color: transparent;
  }
  
  .MuiSlider-markLabel {
    color: var(--secondary-color) !important;
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
    font-size: 20px !important;
  }
  
  .MuiSlider-markActive {
    background-color: white !important;
  }

  .MuiSlider-valueLabel {
    font-family: var(--font-family-main) !important;
    font-size: 14px !important;
    font-weight: bold !important;
    transform: translateY(-8px) translateX(-8px) !important;
  }
  .PrivateValueLabel-label- * {
    -webkit-text-fill-color: white !important;
  }

  .MuiSlider-thumb {
    width: 4px !important;
    height: 12px !important;
    border-radius: 0 !important;
    transform: translateX(8px);
  }
}

.tracks-player {
  .MuiSlider-marked {
    margin-bottom: 0;
    margin-top: 4px;
  }
}