/**
ATHLETE SESSIONS
 */
.athlete-sessions-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  margin-top: 1.75rem;
}

.athlete-sessions-table {
  position: relative;
  width: 100%;
  height: 100%;
  &--export-button {
    position: absolute;
    top:12px;
    right:12px;
    width: fit-content;
    height: fit-content;
    z-index: 99;
  }
}
