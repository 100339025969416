.player__zoom {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;

  button {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5) !important;

    &:hover {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }
}

.player__wrapper {
  position: relative;
}

.player__actions {
  top: 0;
  left: 0;
  position: absolute;
  padding: 4px;
}

.players__list {
  input {
    margin-right: 0.5rem;
  }

  li + li {
    margin-top: 0.125rem;
  }
}

.tracks-player {
  .MuiSlider-marked {
    .MuiSlider-markLabel {
      display: none;
    }

    .MuiSlider-mark {
      width: 7px;
      height: 7px;
      border-radius: 5px;
      transform: translatey(-30%);
    }
  }

  .player-marks-overlay {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 16px;
    height: 7px;
  }

  .player-marks-overlay {
    div {
      background-color: var(--primary-color);
      height: 5px;
      opacity: 1;
      position: absolute;
      z-index: 0;
      color: var(--secondary-color);
      font-family: var(--font-family-main);
      font-size: 15px;

      span {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translatex(-50%);
      }
    }
  }

  .MuiSlider-marked {
    z-index: 10;
  }
}

.players__list li .loader-icon {
  width: 12px;
  height: 12px;
  margin-top: -4px;
  margin-right: 0.5rem;

  .MuiCircularProgress-indeterminate {
    width: 12px !important;
    height: 12px !important;
  }
}

:fullscreen {
  background-color: white;

  .player__wrapper {
    height: 80vh !important;
  }
}

.heatmap-canvas,
#heatmap-canvas-layer {
  pointer-events: none;
}
