.login__wrapper {
  position: absolute;
  margin-top: 141px;
  width: 427px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 24px;
  background: rgb(255,255,255, 0.5);
  background: linear-gradient(180deg, #ffffff00 0, #ffffff44 50px, #ffffff99 90px, #ffffffdd 100%);

  .Mui-error {
    font-size: 0.9rem;
  }
}

.login-form {
  .MuiInputBase-root {
    background-color: white;
  }
}

.login-form__links {
  color: #151515;
  font-family: var(--font-family-alt);
}

.recover-form__intro {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-family: var(--font-family-alt);
  color: #3e3e3e;
}

.login-error {
  color: var(--modal-delete-color) !important;
  margin-bottom: 1.3rem !important;
}

.club-locked-paper {
  margin:auto;
  margin-top:15rem;
}