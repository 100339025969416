@import './styles/reset';
@import 'react-datepicker/dist/react-datepicker.css';

@import './styles/typography';
@import './styles/variables';
@import './styles/mixin';

@import './styles/hacks';
@import './styles/datepicker';
@import './styles/grid';
@import './styles/charts';
@import './styles/layout';
@import "./styles/tabs";
@import './styles/accordion';
@import './styles/sidebar';
@import './styles/footer';
@import './styles/side-drawer';
@import './styles/form-color-picker';
@import './styles/animations';
@import './styles/form-base-chip';
@import './styles/data-grid';
@import './styles/form-list-field';
@import './styles/developer';
@import './styles/comparison';
@import './styles/grounds';
@import './styles/filters';
@import './styles/templates';
@import './styles/table';
@import './styles/calendar';
@import './styles/athlete-sessions';
@import './styles/files';
@import './styles/form-editable-chip';
@import './styles/form-select-field';
@import './styles/form-buttons';
@import './styles/form-link-table';
@import './styles/modal';
@import './styles/tooltip';
@import './styles/form-track-date-field';
@import './styles/loading';
@import './styles/tests';
@import './styles/athlete-card';
@import './styles/slider';
@import './styles/header';
@import './styles/account';
@import './styles/bridge';
@import './styles/athletes';
@import './styles/kpi';
@import './styles/performance';
@import './styles/track';
@import './styles/team';
@import './styles/form-editable-text-field';
@import './styles/sessions';
@import './styles/stepper';
@import './styles/login';
@import './styles/status';
@import './styles/changelog';
@import './styles/dashboard';
@import './styles/player';
@import './styles/button';
@import './styles/webPlayer';
@import './styles/maps';

@import './styles/dnd';
@import './styles/module-reactour';

@import './styles/fixes-safari';

/** @todo inutilizati? */
.club__profile-pic {
  height: 200px;
  object-fit: contain;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 1rem;
  margin: 0;
}

.has-link .MuiSelect-root:hover {
  color: rgb(0, 221, 181);
}

.label-with-measure {
  div{
    margin: 0 !important;
  }

  h2.text-lg {
    line-height: 1 !important;
    margin-bottom: 3px;
  }

  svg {
    margin-bottom: 6px;
    width: 30px;
    height: 30px;
  }
}

.title__strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  &>span {
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: red;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.tags-selector {
  .tags-selector-name {
    text-transform: lowercase;
  }
}

.weather-field .MuiInputAdornment-positionEnd {
  width: 38px;
}

.filters_button {
  span {
    color: var(--secondary-color) !important;
  }
}

.performance-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.75rem;

  .performance-filters__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.75rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .performance-filters__container {
    width: 100%;
  }
}

.categories-tags {
  margin-top: 1rem;
  grid-column: span 5 / span 5;

  .categories-tags__title {
    width: 100%;
    font-family: var(--font-family-alt);
    border-bottom: 2px solid var(--secondary-color);
    font-weight: bold;
    color: var(--secondary-color);
  }
}

.list-field__title {
  font-size: 16px;
  font-family: var(--font-family-alt);
  text-transform: uppercase;
}

.debug-btn {
  border: 1px solid #aaa;
  color: #444;
  display: inline-block;
  padding: 3px 8px;
  margin: 5px;
  border-radius: 6px;
  z-index: 1000;
  font-weight: bold;
  background-color: #ddd;
  cursor: pointer;
}

.info-window {
  border: 1px solid #aaa;
  padding: 5px;
  margin-bottom: 10px;
  max-height: 100px;
  overflow: auto;
}
