/** STEPPER */
.stepper__row {
  padding: 0 12px;

  .MuiStepLabel-label {
    .general-bold-text();
  }
  .MuiTypography-caption {
    font-family: var(--font-family-alt);
    font-weight: bold !important;
  }
}

.stepper__column-center {
  align-items: center;
}

.stepper__column-center .MuiStepper-root {
  width: 100%;
}

.stepper__column-right {
  justify-content: flex-end;
  padding-right: 16px !important;
}

.stepper__column-left,
.stepper__column-right {
  display: flex;
  align-items: flex-start;

  &>span {
    margin-top: 0.4rem;
  }

  button {
    background-color: var(--primary-color);
    color: #fff;

    &:hover {
      color: var(--secondary-color);
    }
  }
}
