/** SideDrawer */
.drawer-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  flex: 0 0 52px;

  h4 {
  @apply font-montserratBold;
    font-size: 18px;
    /* @todo standarize MUI */
  }
}

.sidedrawer__wrapper {
  flex: 1;
}

.drawer-actions {
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.drawer-wrapper>div {
  height: 100vh;
}

.chip-counter-header {
  margin: 5px !important;
  background-color: #828282 !important;
  color: #ffffff !important;
  font-family: var(--font-family-main);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.sidedrawer-card {
  display: flex !important;
  justify-content: end !important;
}

