.performance {
  .performance__category {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.25rem;
  }
}

.profile-messages-box {
  display:flex;
  gap: 1rem;
}

.spaced-prefix-loader-text {
  @apply ml-2 inline-block min-w-8;
}
