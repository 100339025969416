.calendar_match {
  border: solid 1px;
  border-color: var(--secondary-color);
}

.calendar_session {
  color: white;
}

.calendar_list_element {
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 4px !important;
  padding: 0 10px;
  font-family: var(--font-family-main);
  font-weight: 400;
  font-size: 13px !important;
  line-height: 18px !important;
  letter-spacing: 0.16px !important;
  border-radius: 12px;
}

.calendar__header__month {
  color: rgb(130 130 130);
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: var(--font-family-alt);
  font-weight: 600;
  column-gap: 1rem;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  display: inline-grid;
  width: 100%;
}

.calendar__header__datepicker {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}


.calendar__header__navigation {
  position: absolute;
  right: 0;
  top: 0.25rem;
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 0.5rem;

  .MuiButtonBase-root {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0 2px 4px -2px;
  }
}

.calendar__next-match {
  position: absolute;
  right: 12rem;
  bottom: 1rem;

  .label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: var(--font-family-alt);
    text-align: center;
    color: rgb(209, 213, 219);
  }

  .value {
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: var(--font-family-alt);
    font-weight: bold;
    text-align: center;
    color: rgb(220, 38, 38);
  }
}

.legend-item-box {
  margin-top:16px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-family: var(--font-family-main);
  font-weight: 400;
  font-size: 16px;
}
