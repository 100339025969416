.track-date-field:hover {
  .MuiInputBase-root {
    cursor: pointer;
  }

  input,
  .MuiInputAdornment-root {
    color: var(--primary-color);
    cursor: pointer;
  }
}
