.MuiSvgIcon-root.MuiDropzoneArea-icon {
    display: none !important;
}

.MuiDropzoneArea-root{
    display: flex ;
    justify-content: center;
    align-items: center;
    min-height: 170px !important;
}

.MuiDropzoneArea-textContainer {
    text-align: center;
    word-wrap: wrap;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    justify-self: center !important;
    p{
    font-size: 20px !important;
    }
    svg{
        margin: 0 auto !important;
    }
}

.MuiDropzoneArea-text {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
    line-height: normal !important;
}

.MuiDropzonePreviewList-root{
    width: fit-content !important;
}

.MuiDropzonePreviewList-image {
    height: 150px !important;
    width: 150px !important;
    margin: 0 auto;
  }

.preview-file {
    padding-bottom: 10px !important;
    .MuiDropzonePreviewList-imageContainer {
        height: 45px !important;
    }
}

  #dropzone-card-box-img > span.MuiBadge-root {
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
  }

.files-tracks-drawer-field:first-child {
    margin-left: -3px !important;
}
.files-reprocess {
    position: relative !important;
    &--loader {
       position: absolute !important;
       top: 0.25rem !important;
       left: -1.625rem !important;
    }
}

.mediaRecorder-preview {
    border: 1.5px solid #dfdfdf;
    border-radius: 4px;
    padding: 4px;
}
.mediaRecorder-preview > div:not(:last-child) {
    margin-bottom: 1.25rem;
}

.pending-file div svg path{
    fill: var(--tertiary-gradient-2) !important;
    stroke: var(--tertiary-gradient-2) !important;
}