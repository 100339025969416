/** TABLE **/
.MuiDataGrid-toolbarContainer {
  button {
    border: 1px solid transparent;
    height: 30px;
    padding: 0.5rem 1rem;
    background-color: #fff;
    font-size: 1.125rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    line-height: 1.333;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 18px;
    letter-spacing: 0.4px;

    &:hover {
      background-color: #bffff3 !important;
    }

    .MuiButton-label {
      font-size: 0.875rem !important;
    }
  }
}

.MuiDataGrid-root.MuiDataGrid-autoHeight {
  overflow: hidden;
}

.MuiDataGrid-root .MuiDataGrid-cell .MuiInputBase-input:focus {
  background-color: #fff;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}


.MuiDataGrid-root.editable-table .MuiDataGrid-cell {
  border-bottom: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  overflow: visible !important;
}

.MuiDataGrid-root:not(.table__scroll-horizontally) .MuiDataGrid-window {
  overflow-x:visible !important;
  overflow-y:visible !important;
}

.MuiDataGrid-root.MuiDataGrid-autoHeight {
  overflow: visible !important;
}

