/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 20px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) transparent;
}

