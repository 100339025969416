@font-face {
  font-family: 'Montserrat-Regular';
  src: url(../font/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(../font/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url(../font/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

.MuiTypography-colorSecondary {
  color: var(--primary-color) !important;
}

.MuiTypography-colorPrimary {
  color: var(--secondary-color) !important;
}

.MuiTypography-subtitle1 {
  font-family: var(--font-family-alt) !important;
  font-weight: 700 !important;
}

.font-bold {
  font-weight: 700;
}

.unit {
  font-family: var(--font-family-alt);
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize !important;
}

h4.MuiTypography-root {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 48px;
    height: 48px;
  }
}

h4.MuiTypography-root::after {
  content: none !important;
  border: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.disabled-text {
  color: var(--label-disabled-color) !important;
}

.underline {
  border-bottom: 1px solid var(--border-card-color); /* You can customize the color and thickness of the line */
    }
