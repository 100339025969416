.horizontal-percentage-bar-chart {
  width: 100%;
  margin-bottom: 1rem;

  .hpbc-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }

  .chart {
    width: calc(100% - 9rem) !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  rect {
    transition: all 0.3s;
  }

  .title {
    width: 80%;
    font-size: 18px;

    span {
      position: relative !important;
      top: 1px !important;
    }

    span#zone-index, span#zone-legend {
      font-family: var(--font-family-alt);
      font-size: 18px;
      top: 1.5px !important;
    }

    @media (max-width: 700px) {
      span#zone-legend {
        display: none;
      }
    }
  }

  .value {
    font-size: 24px;
    line-height: 0.65;

    small {
      line-height: 0.75;
      margin-left: 0.5rem;
    }
  }

  .uom {
    display: inline-flex;
    align-items: flex-end;
    line-height: 0.7;
    font-size: 1.125rem;
  }

  .percentage {
    font-family: var(--font-family-alt);
    font-weight: 600;
    font-size: 1.25rem;
    width: 20%;
    text-align: right;
  }

  .meta {
    font-family: var(--font-family-alt);
    display: inline-flex;
    justify-content: flex-end;
    align-items: baseline;
    width: 9rem;

    small {
      font-size: 1.125rem;
    }
  }

  .label--value {
    text-align: right;
    width: 9rem;
    justify-content: flex-end;
    padding-right: 0.125rem;
  }

  .label--unit {
    width: 5rem;
  }
}

.highcharts-container {
  .side-label {
  @apply font-bold font-montserratBold;

    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 2px;
    }
  }
}

.highcharts-legend-item {
  text {
    font-size: 14px !important;
    color: var(--secondary-color) !important;
    fill: var(--secondary-color) !important;
    font-family: var(--font-family-alt);
    font-weight: bold;
  }
}

.highcharts-loading {
  opacity: 0.7 !important;
}

.highcharts-loading-inner {
  display: block;
}

.highcharts-loading-inner,
.highcharts-loading-inner:before,
.highcharts-loading-inner:after {
  background: #dfdfdf;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.highcharts-loading-inner {
  color: #dfdfdf;
  text-indent: -9999em;
  margin: 0 auto;
  top: 50% !important;
  position: relative;
  font-size: 11px;
  /*-webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);*/
  transform: translate3d(-50%, -50%, 0);
  /*-webkit-animation-delay: -0.16s;*/
  animation-delay: -0.16s;
}

.highcharts-loading-inner:before,
.highcharts-loading-inner:after {
  position: absolute;
  top: 0;
  content: '';
}

.highcharts-loading-inner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.highcharts-loading-inner:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/** POLAR CHART */
.polar-labels {
@apply font-montserratRegular text-2xl;

  height: 83px;
  width: 110px;

  span {
    display: block;
  }

  svg {
    margin: 0 auto;
  }

  &.polar-labels__0 {
    text-align: center;
    transform: translateY(-23px);
    margin-bottom: 20px;

    span {
      margin-top: 15px;
    }
  }

  &.polar-labels__90 {
    text-align: right;
    transform: translateX(-10px);

    svg {
      margin-right: 0;
    }

    span {
      margin-top: 15px;
    }
  }

  &.polar-labels__180 {
    text-align: center;
    display: flex;
    flex-direction: column;
    transform: translateY(30px);
    margin-top: 20px;

    svg {
      order: 2;
      margin-top: 15px;
    }

    span {
      order: 1;
    }
  }

  &.polar-labels__270 {
    text-align: left;
    transform: translateX(8px);

    svg {
      margin-left: 0;
    }

    span {
      margin-top: 15px;
    }
  }
}

.dropdown--button__small {
  span {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.chart-widget {
  margin-top: 10px !important;
  color: var(--secondary-color) !important;

  h5 {
    font-family: var(--font-family-alt);
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    svg {
      display: inline-block;
      margin-right: 25px;
    }
  }

  .bursts-brakes-title {
    display: flex;
    padding-left: 25px !important;
    padding-right: 10px !important;
    margin-bottom: 28px;

    .label {
      font-family: var(--font-family-alt);
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }

    .number {
      margin-left: auto;
      font-size: 24px;
      font-family: var(--font-family-alt);
      font-size: 24px;
      font-weight: 400;
    }
  }
}

.chart-legend {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;

  li {
    display: inline-block;

    & + li {
      margin-left: 30px;
    }
  }

  .chart-legend--dot {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    border-radius: 100%;
    margin-right: 4px;
  }
}

.highcharts-axis-title {
  font-size: 14px !important;
  color: var(--secondary-color);
  color: var(--secondary-color) !important;
  fill: var(--secondary-color) !important;
}

.chart-scatter {
  .highcharts-axis-title {
    display: flex !important;
    align-items: center;

    svg {
      margin-left: 15px;
    }
  }
}

.chart--variwide {
  .highcharts-xaxis {
    width: 100%;

    .highcharts-axis-title {
      display: inline-flex !important;
      align-items: center;
      justify-content: flex-end;
      width: 100% !important;
      left: 0 !important;

      svg {
        margin-left: 30px;
      }
    }
  }

  .highcharts-yaxis {
    height: 100%;

    .highcharts-axis-title {
      display: inline-flex !important;
      justify-content: flex-end;
      align-items: center;
      margin-left: -10px !important;
      font-size: 14px;

      svg {
        margin-left: 30px;
      }
    }
  }

  .highcharts-text-outline {
    display: none;
  }
}

.custom-bottom-legend {
  margin-bottom: 3rem !important;
@apply flex justify-center items-center;

  span {
  @apply font-montserratBold text-lg inline-flex items-center;
    font-size: 14px;

    svg {
      margin-right: 10px;
    }

    & + span {
      margin-left: 50px;
    }
  }
}

.vertical-spacing__gauge {
  padding-top: 75px !important;
}

.gauge-label {
@apply flex flex-col items-center text-center;
  font-family: var(--font-family-alt);
  margin-top: 48px;

  .gauge-label--title {
    font-size: 18px;
    margin-top: 12px;
    font-family: var(--font-family-alt);
    font-weight: bold;
  }

  .gauge-label--measure {
    font-size: 24px;
    font-weight: 400;
    margin-top: 6px;

    small {
      font-size: 18px;
    }
  }
}

.gauge-center-icon {
  position: absolute;
  top: 38%;
  left: calc(50%);
  transform: translatex(-50%);
  z-index: 10;
  text-align: center;

  & > div {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .gauge-center-icon {
    top: 41.5%;

    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.label--unit {
@apply font-montserratRegular;

  font-size: 18px;
  line-height: 1;
}

.highcharts-plot-band-active {
  fill: var(--primary-gradient-2);
}

.simple-h-chart__wrapper {
  display: flex;

  .simple-h-chart__left,
  .simple-h-chart__right {
    font-family: var(--font-family-alt);
    font-weight: bold;
    font-size: 18px;
    padding-top: 35px;
    width: 100px;
    display: inline-flex;
    justify-content: center;
  }

  .simple-h-chart__center {
    width: calc(100% - 200px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.imu-kpi-charts {
  margin-bottom: 25px !important;
}

.imu-kpi-chart-label-count {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin: 0 !important;
  padding-right: 2rem !important;
  color: var(--secondary-color) !important;
}

.speed-zones-chart {
  position: relative !important;
}

.stats-legend-box {
  legend {
    font-size: 16px !important;
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
    color: var(--secondary-color) !important;
    fill: var(--secondary-color) !important;
  }

  span {
    font-size: 16px !important;
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
    color: var(--secondary-color) !important;
    fill: var(--secondary-color) !important;

  }
}

.summary-orderby-dashboard {
  legend {
    font-size: 12px !important;
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
    color: var(--secondary-color) !important;
    fill: var(--secondary-color) !important;
  }

  span {
    font-size: 12px !important;
    font-family: var(--font-family-alt) !important;
    font-weight: bold !important;
    color: var(--secondary-color) !important;
    fill: var(--secondary-color) !important;

  }
}

.highExtWork-label {
  color: var(--primary-gradient-4) !important;
}

.radiobutton-label {
  font-size: 14px !important;
  font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
  color: var(--secondary-color) !important;
}

.zonechart-label {
  margin-left: 54px !important;
}

.simple-chart {
  padding-right: 30px;
}

.stacked-column-chart-tooltip {
  position: relative !important;
  background-color: white !important;

  .stacked-column-chart-cover-top {
    position: absolute !important;
    height: 6px !important;
    width: 40px !important;
    background-color: white !important;
    margin-left: auto !important;
    margin-right: auto !important;
    top: -5px !important;
    left: 0 !important;
    right: 0 !important;
    background-color: white !important;
  }

  .stacked-column-chart-cover-bottom {
    position: absolute !important;
    height: 6px !important;
    width: 40px !important;
    background-color: white !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.basic-kpi-gauge-row {
  height: fit-content !important;
  margin-top: 50px !important;
}

.zone-radio-box {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  gap: 1rem !important;
  padding-right: 12.25px !important;
}

.horizontal-perc-value {
  font-size: 18px !important;
  font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
}

.highcharts-gauge {
  position: relative;

  .highcharts-tick {
    stroke: var(--stroke-gray) !important;
    display: none !important;
  }

  .highcharts-axis-line {
    stroke: var(--stroke-gray) !important;
  }
}

.map-compass {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  color: #fff;
  width: 36px;
  height: 36px;
}

.title-stats-widget {
  h4 {
    cursor: pointer !important;
    text-decoration: underline !important;
    font-size: 18px !important;

  }

  h4:hover {
    color: var(--primary-gradient-2) !important;
  }
}

.highcharts-reset-zoom-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  svg {
    width: 20px;
    height: 20px;
  }
}

.MuiSlider-markActive {
  background-color: #828282 !important;
}