.comparison-categories {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
}

.comparison-columns {
  font-family: var(--font-family-alt);
  font-weight: bold;
}

.comparison-profiles-box {
  padding: 0 12px !important;
  margin-top: 0.5rem !important;

  .MuiListItem-root {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.comparison-series-box {
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
  margin: 0 !important;
}
