@keyframes icon-spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes icon-opacity {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
/*
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-400 {
   transition-duration: 400ms;
}
*/

.loading-gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #f8f8f8 8%, #f0f0f0 38%, #f8f8f8 54%);
  background-size: 1000px 640px;

  position: relative;
  pointer-events: none;
  opacity: 0.85;

  &.loading-gradient__dark {
    background: #f6f7f8;
    background: linear-gradient(to right, #bdbdbd 8%, #818181 38%, #bdbdbd 54%);
    background-size: 1000px 640px;
  }
}

.item-highlight {
  p {
    transition: text-shadow 1s;
    will-change: text-shadow;
  }

  &.item-new {
    p {
      text-shadow: 0 0 1px rgb(72, 150, 135);
    }
  }

  .MuiBox-root p:first-child {
    width: 148px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.row-not-ready {
  .loading-gradient;
}
