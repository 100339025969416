/** Color picker **/
.color-picker__picker {

  .color-picker__swatch {
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .color-picker__popover {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 2000;
    width: 210px;
    padding: 5px;
    background-color: white;
    border-radius: 12px;

    input {
      text-align: center;
      padding: 0.25rem 0.5rem;
      font-size: 1rem;
      width: 100%;
      margin-top: 8px;
      border: 1px solid var(--border-default);
      border-radius: 3px;
    }
  }

  .color-picker__swatches {
    display: flex;
    padding: 12px;
    flex-wrap: wrap;
  }

  .color-picker__swatch {
    padding: 0;
    cursor: pointer;
    outline: none;
    width: 27px;
    height: 27px;
    border-radius: 8px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px var(--border-default), inset 0 0 0 1px var(--border-default);
    margin: 1px;
  }

  &.disabled,
  &.readonly {
    .color-picker__swatch {
      cursor: default;
      box-shadow: 0 0 0 1px var(--border-disabled), inset 0 0 0 1px var(--border-disabled);
    }
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    transform: translatey(0px);
  }

  100% {
    opacity: 1;
    transform: translatey(0px);
  }
}
