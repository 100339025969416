/** EDITABLE SELECT FIELD */
.editable-select-field {
  .action {
    margin-right: 1.5rem;
  }
}

/** SELECT FIELD */
.MuiAutocomplete-listbox,
.select-field {
  .select-field__option {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0;
    font-family: var(--font-family-main);
    color: var(--secondary-color);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .select-field__value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  .select-field__tags {
    display: flex;
    flex-direction: row;

    .select-field__tags__bg {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 9999px;
    }
  }

  &.readonly .MuiTextField-root:hover {
    input {
      pointer-events: none;
    }
    fieldset {
      border-color: #828282 !important;
      border-width: 2px;
    }
  }
}

.MuiTextField-root.select-field.readonly {
  .MuiInputBase-root {
    pointer-events: none !important;
  }
}

.MuiTextField-root .Mui-disabled {
    color: var(--stroke-gray) !important;
}


/** EDITABLE SELECT FIELD */
.editable-select-field {
  .action {
    margin-right: 1.5rem;
  }
}

/** SELECT FIELD */
.select-field {
  .select-field__option {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0;
    font-family: var(--font-family-main);
    color: var(--secondary-color);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .select-field__value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  .select-field__tags {
    display: flex;
    flex-direction: row;

    .select-field__tags__bg {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 9999px;
    }
  }
}

.select-type {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .select-type__filter-label {
    margin-right: 4rem;
  }

  input {
  @apply mr-2;

    width: 22px;
    height: 22px;
  }

  label {
  font-family: var(--font-family-alt);
  font-weight: bold;

  @apply inline-flex items-center;

    &+label {
    @apply ml-16;
    }
  }
}

.MuiSelect-outlined {
  height: 19px !important;
}
