.carousel-title {
  width: fit-content !important;
  margin-top: 0.25rem;
  display: flex !important;
  flex-direction: row;
  align-items: center !important;

  span {
    div {
      margin-left: 0 !important;
    }
  }

  h4 + span {
    margin-left: auto !important;
  }
}

.carousel-chip {
background-color: var(--primary-gradient-2);
height:fit-content;
padding: 0.1rem 0.6rem;
color: var(--text-secondary);
font-family: var(--font-family-main);
font-weight:450;
border-radius: 1rem;
}

.team-expired-chip {
  background-color: var(--secondary-color);
  height:fit-content;
  padding: 0.1rem 0.6rem;
  color: white;
  font-family: var(--font-family-main);
  font-weight:450;
  border-radius: 1rem;
  position: absolute;
  right: 25px;
  top: 15px;
}

.label-has-link {
  .MuiSvgIcon-root {
    width: 24px;
    height: 22px;
    margin-bottom: 0;
    margin-left: 5px;
  }
}
/*
.llWWYgiSMwz2yeLmfbNu {
  grid-gap: -12px !important;
  column-gap: 1rem !important;
}

.llWWYgiSMwz2yeLmfbNu > div:nth-child(n+3) {
  margin-top: -14px;
}*/

._8qSajFdCl4AoQc7D5GA {
  align-items:start !important;
}

.default-dashboard-stats {
  &--paper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100% !important;
}
&--message {
  margin-top: -25px !important;
}
  & + span {
    display: none !important;
  }
}

.dashboard-link {
  color: var(--secondary-gradient-3);
  &:hover {
    color: var(--secondary-gradient-4);
  }
}

.academy-video {
  path {
    background-color: var(--primary-gradient-2) !important;
  }
path:first-child {
    fill: white !important;
}
}

.academy-link-container {
  height: 112px;
  &:hover {
    background-color: var(--primary-gradient-0);
    > div > div > div > div > div {
      background-color: var(--primary-gradient-1);
    }
  }
}

.dashboard-link-latest {
  &:hover {
    background-color: var(--primary-gradient-0);
    > div {
      background-color: var(--primary-gradient-1);
    }
  }
}

.link-icon-academy {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  width: 25px;
  display: flex;
  justify-content: center;
  height: 25px;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right:10px;
  top:10px;
  cursor: pointer;
}

.dashboard {
  .highcharts-container,
  .highcharts-root{
    width: 99% !important;
  }
}
