.athlete-list-selector-option {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.match-distance-selector {
  width: 13rem;
}

.multiple-filters-chip {
  .MuiChip-root {
    margin-top:5px !important;
    position: relative;
    top:-1.75px;
  }
}

.MuiGridFilterForm-columnSelect:hover
label {
  color: var(--primary-gradient-2);
}
.MuiGridFilterForm-operatorSelect:hover 
label {
  color: var(--primary-gradient-2);
}