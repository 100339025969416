.players-loading {
    width: fit-content !important;
    padding: 0 !important;
    div:first-child {
      width: 22px !important;
      height: 22px !important;
      position:relative !important;
      top: 5px !important;
      z-index: 1000 !important;
    }
  }

  .player-speed-popover {
    span {
      div {
        margin-left: 0 !important;
      }
    }
  }

.tracks-player  {
  .player-data-overlay {
    transition: opacity 0.3s;
    pointer-events: none;
    overflow: hidden !important;
    height: 13px;
    position: absolute;
    left: 0;
    right: 0;
    top: 8px;
    z-index: 7;
    opacity: 0;

    div {
      background-color: rgb(123,222,109);
      height: 100%;
    }
  }

  .player-marks-overlay {
    pointer-events: none;
    top: 12px;
    z-index: 5;
  }

  .MuiSlider-root {
    z-index: 10;
  }

  &:hover {
    .player-data-overlay {
      opacity: 0.5;

    }
  }

}

.webplayer__players-table {
  .dsl__container {
    border: 1px solid #ccc;
    border-radius: 6px;

    h3 {
      text-align: left;
      padding: 2px 15px 2px 10px;
      font-weight: bold;
      width: 83px;
    }
  }

  .dsl__list-title {
    @apply flex flex-row items-center pl-2;
    display:flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    align-items: center;

    .dsl__list-actions {
      @apply w-auto grow;
    }
  }

  .dsl__list {
    border: 1px solid #ccc;
    border-radius: 6px;
    min-height: 4rem;

    & + .dsl__list {
      margin-top: 1.5rem;
    }
  }

  .dsl__item {
    display: flex;
    align-items: center;

    & + .dsl__item {
      border-top: 1px solid #eee;
    }
  }

  .color-picker__picker {
    position: relative;
  }

  /** @todo move away */
  .dsl__list-actions {
    display: flex;
  }

  .athlete-buttons {
    display: flex;
    gap: 0.25rem;
  }

  .athlete-name,
  .athlete-extra-data__s,
  .athlete-extra-data__hr {
    @apply text-right;

    width: 50px;
    min-width: 50px;
  }

  .athlete-visibility,
  .athlete-info,
  .athlete-color {
    width: 25px;
    min-width: 25px;
  }
}

@media (max-width: 1160px) {
  .athlete-extra-data__s,
  .athlete-extra-data__hr {
    display: none !important;
  }
}
