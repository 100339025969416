/** FOOTER **/
/*.footer {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}
*/

#bridge-chat-box {
  display: flex !important;
}

.footer {
  .academy-button {
    button{
      padding:0 !important;
    }
  }
}

.gpexe-version-button {
  .MuiButton-label {
    font-family: var(--font-family-alt) !important;
  }
}

#stop-recording-video {
  position: fixed;
  bottom: 70px;
  right: 20px;
  color: white;
  display: inline-block;
  padding: 6px 12px;
  border-radius: 1rem;
  z-index: 1000;
  background-color: var(--modal-delete-color);
  cursor: pointer;
  animation: pulse 1.75s infinite;
  font-family: var(--font-family-main);
  font-weight: 600;
  font-size: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
