.react-datepicker__header {
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 16px;
}

.react-datepicker {
  border-radius: 24px;
  border-color: #bdbdbd;
  font-family: 'Work Sans', sans-serif;
}

.react-datepicker__month-container {
  border-radius: 24px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--secondary-color);
}

.react-datepicker__day--selected {
  background-color: #00ffd1;
  border-radius: 100%;
}

.react-datepicker__day--selected:hover {
  background-color: #00ffd1;
  border-radius: 100%;
}

.react-datepicker__day:hover {
  border-radius: 100%;
}

.react-datepicker__day--keyboard-selected {
  background-color: #00ffd1;
  border-radius: 100%;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #00ffd1;
  border-radius: 100%;
}

.react-datepicker__day--today {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #00ffd1;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* DatePicker */

.datepicker-class {
  button.MuiButtonBase-root.MuiIconButton-root {
    padding: 8px !important;
    margin-right: 2px !important;
  }

  .MuiInputAdornment-positionEnd {
    margin-right: -0.75rem;
  }

  &.disabled {
    fieldset {
      border-color: var(--border-disabled) !important;
    }
  }

  &.readonly {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
    }

    .MuiIconButton-root {
      pointer-events: none;
    }
  }
}

.datepicker-class-Drawer button.MuiButtonBase-root.MuiIconButton-root {
  padding: 8px !important;
  margin-right: -16px !important;

}
