.body-wrapper.maintenance {
  &::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 1000;
  }

  #content_gpexe {
    overflow: hidden;

    & > header {
      z-index: 2000;
    }
  }
}

.central-message,
.maintenance-message {
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
