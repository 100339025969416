/* LOADING TOP BAR */
.loading-top-bar {
  position: absolute;
  top: 52px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 5px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-origin: content-box;
    background-size: 200%;
    background-image: linear-gradient(to left, hsl(169, 100%, 87%) 32%, hsla(169, 100%, 43%, 1) 50%, hsl(169, 100%, 87%) 66%, hsl(169, 100%, 87%) 90%);
    animation: loading-animation 2s infinite;
  }
}

@keyframes loading-animation {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.MuiCircularProgress-colorPrimary {
  color: var(--primary-color) !important;
}

.MuiCircularProgress-colorPrimary {
  color: var(--primary-color) !important;
}
