.template-selector-poppover {
  width: 20rem !important;
  max-height: calc(100vh - 150px);
  display: flex !important;
  flex-direction: column !important;
  z-index: 99999 !important;
}

.template-selector-current {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  line-height: 1.25rem !important;
  padding-left: 1rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 0.5rem !important;
  font-family: var(--font-family-alt) !important;
  font-size: 1rem !important;
  color: var(--secondary-color);
  border-bottom: 1px solid #e5e7eb;
}

.template-name {
  color: var(--secondary-color);
  text-transform: lowercase;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: var(--font-family-alt) !important;
  white-space: nowrap;

  &:first-child{
    font-family: var(--font-family-alt) !important;
    font-weight: 600 !important;
  }
}

.template-list {
  overflow-y: auto;
  height: 100%;
  padding: 0 !important;
}

.team-list {
  max-height: calc(100vh - 150px);
  flex-direction: column !important;
  display: flex !important;
  z-index: 99999 !important;

  .team-list-current {
    /*flex flex-row items-center whitespace-nowrap w-full leading-5 px-6 pb-2 border-b border-solid py-[13px] font-montserratRegular text-base text-primary-gray*/
    color: var(--secondary-color);
    line-height: 1.25rem;
    font-size: 1rem;
    font-family: var(--font-family-alt);
    padding: 13px 1.5rem 0.5rem;
    border-bottom: 1px solid #e5e7eb;
    white-space: nowrap;
    align-items: center;
    flex-direction: row;
    width: 100%;
    display: flex;

    .team-list-current__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: var(--font-family-alt);
      font-weight: 600;
    }
  }

  .team-list-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .team-list-loading {
    padding: 1.5rem 0.75rem;
    font-family: var(--font-family-alt);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  nav {
    height: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-bottom: 1.25rem;
    overflow-y: auto;

    .team-list-item-selected {
      font-family: var(--font-family-alt);
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      color: var(--secondary-color);

      &:first-child{
        font-family: var(--font-family-alt);
        font-weight: 600;
      }
    }

    .team-list-item {
      font-family: var(--font-family-alt);
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      color: var(--secondary-color);
    }

    .team-list-item-label {
      text-transform: uppercase;
    }
  }
}

.popover-exports {
  width: 100% !important;
  display: flex;
  margin-top: 0.2rem;
  padding-left: 4.2rem;
  gap: 6.6rem;
}

.my-stats-header {
  width: 100% !important;
  display: flex;
  margin-top: 0.2rem;
  padding: 0px 15px 0px 35px;
  gap: 71.5px;
}

.show-all {
  display: inline-block !important;
  width: 100% !important;
  text-align: center !important;
  margin: 0.5rem auto 0 auto!important;
  padding: 0.5rem 0 !important;
  font-family: var(--font-family-alt) !important;
  font-weight: bold !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-gradient-0) !important;
  }
}

.MuiButton-startIcon {
  div{
    margin-left: 0 !important;
  }
}

.export-button {
  .MuiPaper-root{
    min-width: 125px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    *{
      width: 100% !important;
    }
  }
}

.MuiPopover-paper h3.title {
  font-weight: bold;
  font-family: var(--font-family-alt);
  margin: 0.5rem 1rem;
}

.disabled-popover-menu-items {
  color: var(--stroke-gray) !important;
  cursor:default !important;
}

.menu-item-checkmark {
  background-color: var(--primary-gradient-2);
  border-radius: 50%;
  padding: 0 0.2rem;
  position: relative;
  left: 0.45rem;
}
