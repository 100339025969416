/**
ATHLETE CARD
 */

.athlete-card-name {
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  text-transform: capitalize !important;
}

.athlete-card-header {
  margin-top: -4px !important;
}

.athlete-card-footer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  &:hover {
    color: var(--primary-color);
  }
}

/**
ATHLETES
 */

.loading-device {
  background-color: white;
  padding: 1rem;
}

.athlete__profile-pic {
  height: 200px;
  max-width: 200px;
  object-fit: cover;
  border-radius: 12px;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.athlete__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.athlete__actions:hover {
  color: var(--primary-color);
}


.icon-select-device {
  cursor: pointer !important;
  position: absolute !important;
  pointer-events: none !important;
  top: 1px;
  right: 7px;
  }

.select-small {
  .MuiOutlinedInput-root {
    height: 26px;
    margin-top: 4px;
  }

  .MuiSelect-select {
    padding: 0 20px 0 0 !important;
    margin-left: 5px;
    background-color: #FFFFFF !important;
  }
  .MuiSelect-icon {
    display: none;
  }
}

.athlete__profile__wrapper {
  max-width: 200px;
}
