.ground-preview-box-drawer {
    div.konvajs-content{
        height: 400px !important;
        width: 655px !important;
        canvas{
            top: 22.5px !important;
            height: 355px !important;
            width: 655px !important;
        }
    }
}

.ground-preview-box-view {
    div{
        width: 700px !important;
    }
    div.konvajs-content{
        height: 400px !important;
        width: 670px !important;
        canvas{
            top: 22.5px !important;
            left: 15px !important;
            height: 355px !important;
            width: 670px !important;
        }
    }
}

.localcoords-title {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    margin: 0 !important;
    padding: 0 !important;
    gap: 0.5rem !important;
    margin-bottom: 1rem !important;

    div{
        padding: 0 !important;
        margin: 0 !important;
    }

}

.ground-box {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}

.googlemaps-container {
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: -5px;
    border-radius: 15px;
    padding: 18px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  }

.ground-color-picker {
    fieldset {
        padding-bottom: 2.5px !important;
    }
}