.Action {
    display: flex;
    width: 12px;
    padding: 6px 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    /*touch-action: none;*/
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) {
        &:hover {
            background-color: var(--action-background, rgb(191, 255, 243));

            svg {
                fill: #757575;
            }
        }
    }

    svg {
        flex: 0 0 auto;
        margin: auto;
        height: 100%;
        overflow: visible;
        fill: #757575;
    }

    &:active {
        background-color: var(--background, rgb(191, 255, 243));

        svg {
            fill: var(--fill, #757575);
        }
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),
        0 0 0 2px #4c9ffe;
    }
}
