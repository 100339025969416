/** VARS */
:root {
  --primary-color: #00ddb5;
  --secondary-color: #828282;
  --text-secondary: #5b5b5b;
  --bg-color: #f3f4f6;

  --primary-gradient-0: #cafff5;
  --primary-gradient-1: #43fcdb;
  --primary-gradient-2: #00ddb5;
  --primary-gradient-3: #00b292;
  --primary-gradient-4: #00876f;
  --primary-gradient-5: #004d41;

  --secondary-gradient-0: #ECE7FC;
  --secondary-gradient-1: #C7B6F5;
  --secondary-gradient-2: #8F6CEC;
  --secondary-gradient-3: #450ADF;
  --secondary-gradient-4: #290686;
  --secondary-gradient-5: #150343;

  --tertiary-gradient-2: #ff7300;
  --tertiary-gradient-3: #ff3700;

  /** COLOR CODES FOR UTILS **/
  --primary-color-rgb: 0, 221, 181;
  --secondary-color-rgb: 130, 130, 130;
  --border-default: #0000003b;
  --border-disabled: #E0E0E0;
  --border-card-color: #c4c4c4;
  --hover-list-color: #cafff5;
  --label-disabled-color: #bbbbbb;
  --club-text-color: #000000;
  --modal-delete-color: #ff3700;
  --background-color-gray: #D2D3D5;
  --stroke-gray: #BDBDBD;
  --green-row-hover: #BFFFF3;

  /** FONTS **/
  --font-family-main: 'Roboto', sans-serif;
  /* --font-family-alt-bold: 'Montserrat-Bold', sans-serif; */
  /* --font-family-alt-semi: 'Montserrat-SemiBold', sans-serif; */
  --font-family-alt: 'Montserrat', sans-serif;

  --font-size-main: 16px;
  --line-height-main: 24px;

  /** CHARTS **/
  --labels-font-size: 14px;
}



