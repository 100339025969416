/** SIDEBAR **/
.left-drawer {
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;

  .left-drawer__outer {
    background-color: #000;
    display: block;
    height: 124px;

    &>div {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 12px;
      padding-top: 18px;
      padding-bottom: 50px;
    }
  }

  .gpexe-icon {
    margin-left: 18px;
    margin-top: 15px;
  }

}

.left-drawer__links {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  height: 100%;

  a {
    font-family: var(--font-family-alt);
    font-size: 20px;
    height: 46px;
    line-height: 46px;
    color: #fff;
    text-decoration: none;
  }

  .link-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    /* @todo group */

    &:hover {
      color: black;
      background-color: var(--primary-color);
    }

    &.link-is-active {
      color: var(--primary-color);

      &:hover {
        color: #000;
      }
    }
  }

  summary {
    /* group */
    background-color: #000;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: var(--primary-color);
      color: black;

      path {
        fill: #000;
      }
    }

    &>div {
      display: inline-flex;
      align-items: center;
    }

    .icon-wrapper {
      padding-left: 19px;
    }

    .label-wrapper {
      white-space: nowrap;
      margin-left: 1.1rem;
    }

    &>div>span,
    &>a {
      font-size: 20px;
      line-height: 46px;
      height: 46px;
      font-family: var(--font-family-alt);
    }
  }

  .details-content {
    .inner {
      padding-left: 58px;
    }

    a {
      height: 32px;
      font-size: 16px;
    }
  }

  &>.link-is-active,
  & .parent-is-active {
    background-color: var(--primary-color) !important;

    span {
      color: black !important;
    }

    path,
    circle,
    rect {
      fill: black !important;
    }
  }
}

.sidebar-open {
  width: 235px;
  color: #fff;

  .left-drawer__links>a,
  summary {
    height: 46px;
  }

  summary {
    position: relative;

    &>span {
      position: absolute;
      right: 0;
    }
  }

  .dropdown-link {
    .details-content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.5s;
      will-change: opacity;
    }

    &.open {
      .details-content {
        opacity: 1;
        max-height: 400px;
      }

      &>summary>span {
        opacity: 1;
        transition: opacity 0.3s;
        will-change: opacity;

        &>svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.sidebar-closed {
  z-index: 100;
  width: 77px;

  a.flex.justify-start,
  summary {
    padding-left: 19px;
    height: 46px;
  }

  summary>span {
    transition: opacity 0.3s;
    will-change: opacity;
    opacity: 0;
  }

  .icon-wrapper {
    padding: 0 !important;
  }

  .dropdown-link {
    position: relative;

    .details-content {
      display: none;
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 10;
      width: 200px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
      background-color: #000;
      border-left: 1px solid var(--background-color-gray);

      &>a>span {
        padding-left: 0 !important;
      }

      a {
        z-index: 100;
        position: relative;
      }

      &::before {
        content: '';
        display: block;
        background-color: #000;
        border-right: 1px solid var(--bg-color);
        border-bottom: 1px solid var(--bg-color);
        transform: rotate(-225deg);
        width: 10px;
        height: 10px;
        position: absolute;
        top: 20px;
        left: -6px;
      }
    }

    &:hover {
      .details-content {
        display: block;
      }
    }
  }

  .sidebar-links-wrapper {
    overflow: visible !important;

    &>a {
      padding-left: 19px;

      &>span {
        display: none;
      }
    }
  }
}

.navlink--inner-1 {
  padding-left: 28px;
}

.navlink--inner-0 {
  padding-left: 19px;
}

details[open] summary~* {
  animation: sweep 0.5s ease-in-out;
}

.switch-ui-container{
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-left: 1.25rem;
  margin-top: 4rem;
  height:47px;
  cursor: pointer;
  &:hover{
    background-color: var(--primary-gradient-2);
    svg {
      path:first-child {
        fill: var(--primary-color)
      }
      path {
        fill: black;
      }
    }
    .switch-ui-title {
      color: black;
    }
  }
  .switch-ui-title {
    font-size: 20px;
    line-height: 46px;
    height: 46px;
    font-family: var(--font-family-alt);
  }
}

.yt-feedback-button {
  margin-top: 20px;
  padding-left: 14px;
  div {
    .feedback-popup-button {
      border:none;
      background-color: black;
      color:white;
      &:hover{
        background-color: var(--primary-gradient-2);
        color:black;
      }
    }
  }
}

.formPopup__bc9 {
  position:absolute;
  z-index:100 !important;
}

.installation-notification {
  opacity: 1;
  animation: appearDisappear 3.5s infinite;
}

@keyframes appearDisappear {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
