.changelog-version {
  padding: 10px 12px 1rem 1rem;
  height:100%;
  &:first-child {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  &-title {
    width:fit-content !important;
    position:relative;
    left:-3.25px;
    div{
      margin-left:0 !important;
    }
  }
  &-changeblock {
      border-bottom: 1px solid var(--border-card-color);
      margin-top: 1px;
      padding-bottom: 0.5px;
  }
  &-headline {
    font-family: var(--font-family-main);
    font-weight: 700;
      span {
        font-weight: 400;
      }
  }
  &-ul {
    font-family: var(--font-family-main);
  }
}
.changelog-version ul li:not(:last-child) {
  display: flex;
  align-items: flex-start;
  margin-top: 1px;
}

.changelog-version ul {
  list-style: none;
}




